@tailwind base;
@tailwind components;
@tailwind utilities;

/* Avenir Font Faces */
@font-face {
  font-family: "Avenir";
  src: url("/fonts/AvenirHeavy.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("/fonts/AvenirMedium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Avenir";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  :root {
    --background: 240, 26%, 96%;
    --foreground: 218, 44%, 8%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 217, 12%, 42%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 193.22 7.84% 45.88%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 192 100% 24%;
    --accent-foreground: 0 0% 100%;

    --destructive: 344, 100%, 40%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
